// extracted by mini-css-extract-plugin
export var backButton = "single-frame-module--backButton--fEQzC";
export var bio = "single-frame-module--bio--vEdJY";
export var blackLine = "single-frame-module--blackLine--sUD6P";
export var cardLogo = "single-frame-module--cardLogo--7GQqc";
export var cardWrapper = "single-frame-module--cardWrapper--lEZk0";
export var framePhoto = "single-frame-module--framePhoto--UqAeo";
export var framePhotoWrapper = "single-frame-module--framePhotoWrapper--nDHFl";
export var linkOut = "single-frame-module--linkOut--OgB1w";
export var location = "single-frame-module--location--XEbQx";
export var locationWrapper = "single-frame-module--locationWrapper--OzMdq";
export var logoWrapper = "single-frame-module--logoWrapper--sh-YL";
export var redLine = "single-frame-module--redLine--lc6Un";
export var socialIconsWrapper = "single-frame-module--socialIconsWrapper--I1OQW";
export var visitWebsite = "single-frame-module--visitWebsite--RFfKj";
export var visitWebsiteWrapper = "single-frame-module--visitWebsiteWrapper--Q9hDI";
export var wrapper = "single-frame-module--wrapper--YFF0I";