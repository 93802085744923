import React from "react"
import * as jumpToBrandStyles from "./jumpToBrand.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { FaTags } from "react-icons/fa"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const JumpToBrand = () => {
  const data = useStaticQuery(graphql`
    {
      frames: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/frames/" } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        edges {
          node {
            id
            frontmatter {
              location
              title
              website
              logo {
                publicURL
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <div className={jumpToBrandStyles.wrapper}>
      <h2>
        <FaTags />
        More Collections
      </h2>
      <ul className={jumpToBrandStyles.linksList}>
        {data.frames.edges.map(edge => {
          return (
            <li key={edge.node.id}>
              <a
                href={`..${edge.node.fields.slug}`}
                className={jumpToBrandStyles.imageLink}
              >
                <img
                  src={edge.node.frontmatter.logo.publicURL}
                  alt={`${edge.node.frontmatter.title} logo`}
                />
                {/* <span>{edge.node.frontmatter.title}</span> */}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default JumpToBrand
