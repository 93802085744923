import React from "react"
import { graphql, navigate } from "gatsby"
import * as singleFrameStyles from "./single-frame.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { GrFacebook, GrInstagram, GrShare } from "react-icons/gr"
import { BiLinkExternal } from "react-icons/bi"
import { FiExternalLink, FiFacebook, FiInstagram } from "react-icons/fi"
import JumpToBrand from "../components/frames/jumpToBrand"
import Seo from "../components/seo"
import { IoIosArrowBack } from "react-icons/io"

const SingleFrame = ({ data }) => {
  const facebookLink = data.markdownRemark.frontmatter.facebookLink
  const instagramLink = data.markdownRemark.frontmatter.instagramLink
  const website = data.markdownRemark.frontmatter.website

  return (
    <div className={singleFrameStyles.wrapper}>
      <Seo title={data.markdownRemark.frontmatter.title} />
      <div aria-hidden="true" className={singleFrameStyles.redLine}></div>

      <div style={{ width: `95%`, maxWidth: `1200px`, margin: `auto` }}>
        <button
          className={singleFrameStyles.backButton}
          onClick={() => navigate("/frames")}
        >
          <IoIosArrowBack />
          Back to Collections
        </button>
      </div>
      <div className={singleFrameStyles.cardWrapper}>
        {/* LOGO */}
        <div className={singleFrameStyles.logoWrapper}>
          <GatsbyImage
            className={singleFrameStyles.cardLogo}
            image={
              data.markdownRemark.frontmatter.logo.childImageSharp
                .gatsbyImageData
            }
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            imgStyle={{ objectFit: `contain` }}
            alt={`${data.markdownRemark.frontmatter.title} logo`}
          />
        </div>
        <div className={singleFrameStyles.locationWrapper}>
          <span className={singleFrameStyles.location}>
            {data.markdownRemark.frontmatter.location}
          </span>
        </div>
        {/* FRAME IMAGE */}
        <div className={singleFrameStyles.framePhotoWrapper}>
          <GatsbyImage
            className={singleFrameStyles.framePhoto}
            image={
              data.markdownRemark.frontmatter.image1.childImageSharp
                .gatsbyImageData
            }
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            imgStyle={{ objectFit: `contain` }}
            alt={`${data.markdownRemark.frontmatter.image1Description}`}
          />
        </div>
        <div className={singleFrameStyles.visitWebsiteWrapper}>
          {/* social links */}
          {facebookLink || instagramLink || website ? (
            <div className={singleFrameStyles.socialIconsWrapper}>
              <a href={facebookLink} target="__blank">
                <GrFacebook />
              </a>
              <a href={instagramLink} target="__blank">
                <GrInstagram />
              </a>
              <a href={website} target="__blank">
                <BiLinkExternal />
              </a>
            </div>
          ) : null}
        </div>
        {/* BIO */}
        <div
          className={singleFrameStyles.bio}
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </div>
      <JumpToBrand />
    </div>
  )
}

export const query = graphql`
  query FrameQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        bio
        image1 {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            id
          }
        }
        image1Description
        image2 {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            id
          }
        }
        image2Description
        location
        title
        website
        facebookLink
        instagramLink
        logo {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            id
          }
        }
      }
    }
  }
`

export default SingleFrame
